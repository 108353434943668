import React, { useRef } from "react";
import CustomCarousel from "../carousal/carousal";
import ArrowPrevSlideIcon from "../../icons/ArrowPrevSlide";
import ArrowNextSlideIcon from "../../icons/ArrowNextSlide";

const AwardsAndRecognition = ({ allAwards }) => {
  const childRef = useRef(null);
  const nextSlid = () => {
    // Check if the child component reference exists
    if (childRef.current) {
      // Call the child component's function
      childRef.current.nextSlid();
    }
  };

  const prevSlid = () => {
    // Check if the child component reference exists
    if (childRef.current) {
      // Call the child component's function
      childRef.current.prevSlid();
    }
  };
  return (
    <div className="section awars">
      {/* <button
        onClick={() => {
          nextSlid();
        }}
      >
        Next
      </button>
      <button
        onClick={() => {
          prevSlid();
        }}
      >
        Prev
      </button> */}
      
      <div className="awards_title">Awards and Recognition</div>
      <div className="award_cards" style={{position:"relative"}}>
      <div className="slider_btns awardsSld-btn">
        <button
          onClick={() => {
            prevSlid();
          }}
        >
          <ArrowPrevSlideIcon
            iconClassName="brandPrev h-36to56 w-36to56"
            id="deskBrandPrev"
            height="36"
            width="36"
          />
        </button>
        <button
          onClick={() => {
            nextSlid();
          }}
        >
          <ArrowNextSlideIcon
            height="36"
            width="36"
            iconClassName="brandNext h-36to56 w-36to56"
            id="deskBrandNext"
          />
        </button>
      </div>
        <CustomCarousel showCardCount={3} ref={childRef} extraClass="sld1">
          {allAwards &&
            allAwards.map((value) => {
              return (
                <div className="cardgap">
                  <div className="award_card">
                    <div className="award_card-img">
                      <img src={value.image} alt="" />
                    </div>
                    <div className="award_card-content">
                      <div className="award_card-logo">
                        <img src={value.icon} alt="" />
                      </div>
                      <div className="award_card-text">{value.desc}</div>
                      <a
                        href={value.url}
                        target="_blank"
                        className="award_read"
                      >
                        Read Article
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </CustomCarousel>

        {/* <div className="award_card">
            <div className="award_card-img">
              <img src="./images/Blog_Image.jpg" alt="" />
            </div>
            <div className="award_card-content">
              <div className="award_card-logo">
                <img src="./images/forbes.svg" alt="" />
              </div>
              <div className="award_card-text">
                MetaShop: Awarded $100,000 non-dilutive grant and dedicated
                mentorship from Google's Women Founders Fund
              </div>
              <a href="/" className="award_read">
                Read Article
              </a>
            </div>
          </div>
          <div className="award_card">
            <div className="award_card-img">
              <img src="./images/MetaShop_party.jpg" alt="" />
            </div>
            <div className="award_card-content">
              <div className="award_card-logo">
                <img src="./images/tie.svg" alt="" />
              </div>
              <div className="award_card-text">
                MetaShop: Awarded $100,000 non-dilutive grant and dedicated
                mentorship from Google's Women Founders Fund
              </div>
              <a href="/" className="award_read">
                Read Article
              </a>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default AwardsAndRecognition;
