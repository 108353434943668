// components/CustomCarousel.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomCarousel = React.forwardRef(
  ({ extraClass, children, showCardCount = 3 }, ref) => {
    const slider = React.useRef(null);

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: showCardCount,
      slidesToScroll: 1,
      draggable: true,
      centerMode: false,
      centerPadding: "10",
      autoplay: false,
      infinite: false,
      arrows: false,

      autoplaySpeed: 2000, // Adjust autoplay speed as needed
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
            centerMode: false,
            infinite: true,
            centerPadding: "20px"
          },
        },
      ],
    };

    const nextSlid = () => {
      console.log("Child function called from parent");
      slider.current.slickNext();
      // Perform any actions you want the child component to execute
    };

    const prevSlid = () => {
      console.log("Child function called from parent");
      slider.current.slickPrev();
      // Perform any actions you want the child component to execute
    };

    // Expose the child function to the parent component through the ref
    React.useImperativeHandle(ref, () => ({
      nextSlid,
      prevSlid,
    }));

    return (
      <div>
        <Slider ref={slider} {...settings} className={`carousel ${extraClass}`}>
          {children}
        </Slider>
      </div>
    );
  }
);

export default CustomCarousel;
