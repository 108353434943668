const footerMenu = [
  // {
  //   text: "Explore",
  //   link: "/explore",
  // },
  // {
  //   text: "Our Team",
  //   link: "/team",
  // },
  {
    text: "Contact Us",
    link: "/contact",
  },
  {
    text: "API",
    link: "api",
  },
  {
    text: "Privacy Policy",
    link: "https://sites.google.com/metashopai.com/privacy-policy/home",
  },
  {
    text: "Terms of Service",
    link: "https://sites.google.com/metashopai.com/termsofservice/home",
  },
];

module.exports = footerMenu;
