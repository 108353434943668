import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/lazy";
import { Pagination, Navigation } from "swiper";
import ArrowPrevSlideIcon from "../../icons/ArrowPrevSlide";
import ArrowNextSlideIcon from "../../icons/ArrowNextSlide";
import models3d from "../../Data/models3d";
import Picture from "../Picture";

export default function Index() {
  return (
    <section className="relative min-h-[400px] lg:mb-50to100">
      <Picture
        mobile="./images/gradients/CP3D-mobile.png"
        desktop="./images/gradients/CP3D-desktop.png"
        mobHeight="600"
        mobWidth="400"
        deskHeight="800"
        deskWidth="1920"
        altText="gradiet"
        className="absolute top-0 left-0 -z-10 h-full w-full"
      />
      <div className="flex flex-col items-center lg:container lg:flex-row lg:gap-x-14to264">
        <div
          className={`hero1 container mt-8 shrink grow basis-0  lg:mt-0 lg:overflow-hidden lg:py-30to140`}
        >
          <div className="mb-8 text-center">
            <h2 className="mb-14to30 w-full text-22to42 font-bold">
              Create photorealistic 3D assets at scale
            </h2>
            <p className="mx-auto text-14to16 lg:max-w-[70%]">
              Just record a short video using any <br /> mobile or camera
              device.
            </p>
          </div>

          <div className="ctaonmob">
            <button
              onClick={() => window.open("https://app.metashopai.com")}
              className="btnFill"
              style={{ width: "230px" }}
            >
              Try Now
            </button>
            {/* <button
              onClick={() => window.open("https://forms.gle/n3vUZKmxzp8bLYCX7")}
              className="btnOutline"
            >
              Request API
            </button> */}
          </div>
        </div>

        <div className="relative w-full shrink basis-0 lg:grow-[2] lg:overflow-hidden">
          <Swiper
            slidesPerView={1}
            lazy={true}
            navigation={{
              prevEl: ".brandPrev",
              nextEl: ".brandNext",
            }}
            pagination={{
              el: "#brandModelsPagination",
              clickable: true,
              dynamicBullets: true,
              renderBullet: function (index, className) {
                return `<span class="${className} transition-all h-2 w-2 mx-1 rounded-full inline-block bg-accent"></span>`;
              },
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper2"
          >
            {models3d.map((modelBrand, item) => {
              return (
                <SwiperSlide
                  key={item}
                  className="mx-auto w-full border lg:border-none"
                >
                  <iframe
                    title={modelBrand.name}
                    src={modelBrand.url}
                    loading={item === 0 ? "eager" : "lazy"}
                    allowFullScreen={true}
                    className="h-368to736 w-full"
                  ></iframe>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="pagearrow container z-30 mt-4 flex justify-between lg:absolute lg:top-1/2 lg:-translate-y-1/2">
            <ArrowPrevSlideIcon
              iconClassName="brandPrev h-36to56 w-36to56"
              id="deskBrandPrev"
              height="36"
              width="36"
            />
            <div
              id="brandModelsPagination"
              className="mx-auto mt-3 text-center lg:hidden"
            ></div>
            <ArrowNextSlideIcon
              height="36"
              width="36"
              iconClassName="brandNext h-36to56 w-36to56"
              id="deskBrandNext"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
